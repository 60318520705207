@mixin mediaQuery($breakpoint) {
  @if $breakpoint==ph {
    @media only screen and (max-width: 37.5em) {
      @content;
    }
  }

  @if $breakpoint==phld {
    @media only screen and (max-height: 37.5em) {
      @content;
    }
  }

  @if $breakpoint==pd {
    @media only screen and (min-width: 37.5em) and (max-width: 60em) {
      @content;
    }
  }

  @if $breakpoint==dk {
    @media only screen and (min-width: 60em) {
      @content;
    }
  }
}
