$black: #000000;
$white: #ffffff;
$bg-dark: #323232;
$bg-light: #f5f5f5;
$pink: #fc28fc;
$blue: #2dfffe;
$yellow: #fffc38;
$gray: #808080;
$light-gray: #eeeeee;
$b-white: #ededed;
$card-title: #666666;
$bg-footer: #373737;
