@use "app/_styles/colors.scss";
@use "app/_styles/mixins.scss";
@use "app/_styles/utilities.scss";

html {
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: colors.$bg-dark;
  overflow-x: hidden;
}

body {
  padding: 0px !important;
  margin: 0px !important;
  height: 100vh;
}

main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

a {
  &:hover {
    text-decoration: underline;
    text-decoration-color: colors.$white;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

video {
  border-radius: 1em;
}

footer {
  background: colors.$bg-footer;
  color: colors.$white;
  font-family: "Inconsolata", sans-serif;
}

.ContextContainer {
  width: 100%;
  min-height: 100vh;
  background-color: colors.$bg-dark;
  color: colors.$white;
  font-family: "Inconsolata", sans-serif;
  margin-bottom: 10vh;

  .Content {
    min-height: 70vh;

    &-Experience {
      min-height: 45vh;
    }

    .BaseCard {
      background-color: colors.$bg-dark;
      min-height: 50vh;
      width: 65%;
      border-radius: 1em;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;

      &-Experience {
        min-height: 30vh;
      }

      .CardName {
        font-family: "Inconsolata", sans-serif;
        color: colors.$bg-light;
        position: absolute;
        left: -12%;
        top: 150px;

        @include utilities.mediaQuery(ph) {
          font-size: 36px;
        }

        @include utilities.mediaQuery(pd) {
          font-size: 76px;
        }

        @include utilities.mediaQuery(dk) {
          font-size: 88px;
        }

        @include utilities.mediaQuery(phld) {
          top: 0px;
        }

        &-Number {
          left: -4%;
          top: 250px;

          @include utilities.mediaQuery(phld) {
            top: 40px;
            left: -10%;
          }

          &-Experience {
            top: 80px;

            @include utilities.mediaQuery(phld) {
              top: 0px;
            }
          }
        }

        &-Education {
          @include utilities.mediaQuery(phld) {
            top: 50px;
          }
        }
      }

      .FloatingImg {
        height: 400px;
        width: 400px;
        object-fit: contain;
        position: relative;
        bottom: -5em;

        @include utilities.mediaQuery(ph) {
          right: -24%;
        }

        @include utilities.mediaQuery(pd) {
          right: -40%;
        }

        @include utilities.mediaQuery(dk) {
          right: -70%;
        }

        @include utilities.mediaQuery(phld) {
          height: 180px;
          width: 180px;
          right: -70%;
          bottom: -4em;
        }
      }
    }
  }

  .CardInformation {
    background-color: colors.$bg-light;
    border-radius: 1em;
    min-height: 30vh;
    width: 40%;
    padding: 3em;

    .ContactItem {
      font-family: "Inconsolata", sans-serif;
      color: colors.$bg-dark;
      margin: 1em 0;

      @include utilities.mediaQuery(ph) {
        font-size: 16px;
      }

      @include utilities.mediaQuery(pd) {
        font-size: 18px;
      }

      @include utilities.mediaQuery(dk) {
        font-size: 22px;
      }
    }
  }

  #AboutProfileIamge {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    @include utilities.mediaQuery(ph) {
      height: 15vh;
    }

    @include utilities.mediaQuery(pd) {
      height: 25vh;
    }

    @include utilities.mediaQuery(dk) {
      height: 30vh;
    }
  }

  #AboutProfileDescription {
    font-family: "Inconsolata", sans-serif;
    color: colors.$bg-dark;
    text-align: center;

    @include utilities.mediaQuery(ph) {
      font-size: 16px;
    }

    @include utilities.mediaQuery(pd) {
      font-size: 18px;
    }

    @include utilities.mediaQuery(dk) {
      font-size: 22px;
    }
  }

  .SectionTitle {
    font-family: "Inconsolata", sans-serif;
    color: colors.$bg-light;
    text-align: left;
    font-size: 34px;
    margin-bottom: 1rem;
  }

  .SectionText {
    text-align: left;
    white-space: pre-line;
    text-justify: inter-word;

    @include utilities.mediaQuery(ph) {
      font-size: 16px;
    }

    @include utilities.mediaQuery(pd) {
      font-size: 18px;
    }

    @include utilities.mediaQuery(dk) {
      font-size: 22px;
    }

    &-Btn {
      color: colors.$bg-light;
      border-radius: 11px;
      text-transform: lowercase;
      margin-top: 2rem;
    }
  }

  a.SectionText {
    color: colors.$bg-light;
  }
}

.ItemSelected {
  text-decoration: underline solid colors.$white;
}

.FooterMenuItem {
  color: colors.$bg-light;
  text-decoration: none;
}

.uk-container-small {
  padding: 0 20px;
}
