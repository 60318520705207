@use "../../_styles/colors.scss";
@use "../../_styles/mixins.scss";
@use "../../_styles/animations.scss";
@use "../../_styles/utilities.scss";

.HomeTitleContainer {
  height: 100vh;
  @include mixins.not-selectable;

  .MainTitle {
    font-family: "Lexend Deca", sans-serif;
    text-shadow: 0px 0px colors.$white;
    color: colors.$bg-light;
    position: relative;
    animation: noise 6s ease 0s infinite alternate;
    margin-bottom: 4rem;

    &:after {
      content: "v.202301";
      text-shadow: 0px 0px colors.$white;
      position: absolute;
      bottom: -5px;
      right: 5px;
    }

    @include utilities.mediaQuery(ph) {
      font-size: 42px;
      &:after {
        font-size: 8px;
      }
    }

    @include utilities.mediaQuery(pd) {
      font-size: 70px;
      &:after {
        font-size: 10px;
      }
    }

    @include utilities.mediaQuery(dk) {
      font-size: 90px;
      &:after {
        font-size: 12px;
      }
    }
  }

  .DownArrow {
    display: block;
    position: absolute;
    bottom: 9em;

    #arrow-animation {
      font-size: 24px;
      animation: arrowanim 6s ease 0s infinite alternate;
    }

    @include utilities.mediaQuery(phld) {
      bottom: 3em;
    }
  }
}

.HomeSectionsContainer {
  margin-top: -15vh;
  min-height: 60vh;

  .CardsSpacing {
    padding: 4em;
    padding-top: 6em;

    .MainCard {
      border-radius: 1em;
      height: 180px;

      .CardBody {
        width: 100%;
        height: 100%;

        &:hover {
          .CardTitle {
            color: colors.$black;
          }
        }

        .CardTitle {
          font-size: 42px;
          color: colors.$card-title;
          font-family: "Inconsolata", sans-serif;
          text-decoration: none;
        }
      }
    }
  }
}

// =================
// Bubbles Animations
// =================

.bubbles-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  max-width: 15rem;
  transform: translateX(-50%);
  opacity: 0.75;
  overflow: visible;
}

.bubbles {
  width: 100%;
  height: auto;

  circle {
    stroke: colors.$pink;
    fill: none;
  }

  > g > g:nth-of-type(3n) circle {
    stroke: colors.$blue;
  }

  > g > g:nth-of-type(4n) circle {
    stroke: colors.$yellow;
  }
}

.bubbles-large {
  overflow: visible;

  > g {
    transform: translateY(2048px);
    opacity: 0;
    will-change: transform, opacity;
  }

  g:nth-of-type(1) {
    animation: up 6.5s infinite;

    g {
      transform: translateX(350px);
    }

    circle {
      animation: wobble 3s infinite ease-in-out;
    }
  }

  g:nth-of-type(2) {
    animation: up 5.25s 250ms infinite;

    g {
      transform: translateX(450px);
    }

    circle {
      animation: wobble 3s infinite ease-in-out;
    }
  }

  g:nth-of-type(3) {
    animation: up 6s 750ms infinite;

    g {
      transform: translateX(700px);
    }

    circle {
      animation: wobble 3s infinite ease-in-out;
    }
  }

  g:nth-of-type(4) {
    animation: up 5.5s 1.5s infinite;

    g {
      transform: translateX(500px);
    }

    circle {
      animation: wobble 3s infinite ease-in-out;
    }
  }

  g:nth-of-type(5) {
    animation: up 6.5s 4s infinite;

    g {
      transform: translateX(675px);
    }

    circle {
      animation: wobble 3s infinite ease-in-out;
    }
  }
}

.bubbles-small {
  overflow: visible;

  > g {
    transform: translateY(2048px);
    opacity: 0;
    will-change: transform, opacity;
  }

  g circle {
    transform: scale(0);
  }

  g:nth-of-type(1) {
    animation: up 5.25s infinite;

    g {
      transform: translateX(350px);
    }

    circle {
      animation: wobble 3s infinite ease-in-out;
    }
  }

  g:nth-of-type(2) {
    animation: up 5.75s infinite;

    g {
      transform: translateX(750px);
    }

    circle {
      animation: wobble 3s infinite ease-in-out;
    }
  }

  g:nth-of-type(3) {
    animation: up 5.25s 250ms infinite;

    g {
      transform: translateX(350px);
    }

    circle {
      animation: wobble 3s 250ms infinite ease-in-out;
    }
  }

  g:nth-of-type(4) {
    animation: up 5.75s 325ms infinite;

    g {
      transform: translateX(180px);
    }

    circle {
      animation: wobble 3s 325ms infinite ease-in-out;
    }
  }

  g:nth-of-type(5) {
    animation: up 6s 125ms infinite;

    g {
      transform: translateX(350px);
    }

    circle {
      animation: wobble 3s 250ms infinite ease-in-out;
    }
  }

  g:nth-of-type(6) {
    animation: up 5.13s 250ms infinite;

    g {
      transform: translateX(650px);
    }

    circle {
      animation: wobble 3s 125ms infinite ease-in-out;
    }
  }

  g:nth-of-type(7) {
    animation: up 6.25s 350ms infinite;

    g {
      transform: translateX(480px);
    }

    circle {
      animation: wobble 3s 325ms infinite ease-in-out;
    }
  }

  g:nth-of-type(8) {
    animation: up 7s 200ms infinite;

    g {
      transform: translateX(330px);
    }

    circle {
      animation: wobble 3s 325ms infinite ease-in-out;
    }
  }

  g:nth-of-type(9) {
    animation: up 6.25s 233ms infinite;

    g {
      transform: translateX(230px);
    }

    circle {
      animation: wobble 3s 275ms infinite ease-in-out;
    }
  }

  g:nth-of-type(10) {
    animation: up 6s 900ms infinite;

    g {
      transform: translateX(730px);
    }

    circle {
      animation: wobble 2s 905ms infinite ease-in-out;
    }
  }
}

@keyframes wobble {
  33% {
    transform: translateX(-50px);
  }

  66% {
    transform: translateX(50px);
  }
}

@keyframes up {
  0% {
    opacity: 0;
  }

  10%,
  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(-1024px);
  }
}

.st0 {
  fill: #ededed;
}

#st1 {
  animation: mouse 1.5s linear infinite;
}

@keyframes mouse {
  0%,
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(90px);
  }
}
