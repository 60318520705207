@use "./colors.scss";

@keyframes noise {
  0% {
    text-shadow: 1px 0px colors.$pink;
    transform: scale(1);
  }
  33% {
    text-shadow: 0px -1px colors.$blue;
  }
  66% {
    text-shadow: -1px 0px colors.$yellow;
  }
  100% {
    text-shadow: 0px 0px colors.$bg-light;
    transform: scale(0.9);
  }
}

@keyframes arrowanim {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translatey(0px);
  }
}
